.doormat-wrapper {
  text-align: justify;
  background: $c-black;

  .region-doormat {
    @include max-width-padding(20);
    overflow: auto;

    .block--menu-block--1 {
      display: none;
      float: left;

      @include breakpoint($b-desktop) {
        display: block;
      }

      .menu-block-wrapper > ul {
        overflow: hidden;

        > li {
          float: left;
          margin: 0 30px 0 0;

          > a {
            display: block;
            color: $c-white;
            text-transform: uppercase;

            &:hover {
              text-decoration: underline;
            }
          }

          > ul {
            > li {
              margin-top: 5px;

              > a {
                display: block;
                color: $c-grey;
                transition: color $transition;

                &:hover {
                  color: $c-white;
                }
              }
            }
          }
        }
      }
    }

    .block--bean--contact {
      color: $c-white;

      @include breakpoint($b-desktop) {
        float: right;
      }

      h2 {
        margin: 0 0 5px;
        color: $c-white;
        font-size: 1rem;
        font-weight: $fw-normal;
      }

      a {
        color: $c-white;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
